import React from 'react';
// import ListingImageGallery from './ListingImageGallery/ListingImageGallery';
import ListingImageGalleryNew from './ListingImageGalleryNew/ListingImageGalleryNew';
import css from './ListingPage.module.css';

const SectionGallery = props => {
  const { listing, variantPrefix ,intl,variants,isPreviewPage,currentUser,isOwnListing} = props;
  const images = listing.images;
  const {listingImagesUrl} = listing?.attributes?.publicData || {};
  const imageVariants = ['scaled-small', 'scaled-medium', 'scaled-large', 'scaled-xlarge'];
  const thumbnailVariants = [variantPrefix, `${variantPrefix}-2x`, `${variantPrefix}-4x`];
  return (
    <div className={css.productGallery} data-testid="carousel">
      {/* <ListingImageGallery
        images={images}
        imageVariants={imageVariants}
        thumbnailVariants={thumbnailVariants}
        variants={variants}
        isPreviewPage={isPreviewPage}
        listingImagesUrl={listingImagesUrl}
      /> */}
      <ListingImageGalleryNew
        images={images}
        imageVariants={imageVariants}
        thumbnailVariants={thumbnailVariants}
        variants={variants}
        isPreviewPage={isPreviewPage}
        listingImagesUrl={listingImagesUrl}
        currentUser = {currentUser}
        currentListing = {listing}
        isOwnListing={isOwnListing}
        intl={intl}
      />
    </div>
  );
};

export default SectionGallery;
